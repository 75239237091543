class Common {
	constructor() {
		this.view = $(window);
		this.view.body = $('body');
		this.globalHeader = new Common.GlobalHeader(this);
		this.scrollmagic = new Common.ScrollMagic(this)
		this.form = new Common.Form(this);
		this.anchorLinks = Common.SmoothScroll.init(this);
		// this.accordion = Common.Accordion.init(this);
		this.audioPlayer = Common.AudioPlayer.init(this);
		this.wpRestApiClient = Common.WP_REST_API_Client.init(this);
	}

	static GlobalHeader = class {
		constructor(common) {
			this.view = $('.cmn-global-header');
			this.view.burger = this.view.find('.burger');
			this.transparent = common.view.body.hasClass('header-transparent');

			this.view.burger.click(this.toggle.bind(this));
			this.view.click(e => {
				if(this.view.hasClass('open')) {
					if(!$(e.target).closest('.cmn-header-inner').get(0) || $(e.target).closest('.close').get(0)) {
						e.preventDefault();
						this.close();
					}
				}
			})
			common.view.scroll(this.scroll.bind(this));
		}
		scroll() {
			if(this.transparent) {
				if(common.view.scrollTop() < 200) {
					this.view.removeClass('scroll');
				} else {
					this.view.addClass('scroll');
				}
			}
		}
		toggle() {
			if(!this.view.hasClass('open')) {
				this.open();
			} else {
				this.close();
			}
		}
		open() {
			this.view.addClass('open');
			// bodyScrollLock.disableBodyScroll(this.view);
		}
		close() {
			this.view.removeClass('open');
			// bodyScrollLock.clearAllBodyScrollLocks();
		}
	}

	static ScrollMagic = class {
		constructor() {
			this.controller = new ScrollMagic.Controller();

			$('[data-magic]').each((i, item) => {
				var scene = new ScrollMagic.Scene({
					triggerElement: item,
					triggerHook: 'onEnter',
					offset: 300
				})
				scene.on('enter', function () {
					$(item).addClass('active');
				})
				// scene.on('leave', function () {
				// 	$(item).removeClass('active');
				// })
				scene.addTo(this.controller);;
			})
		}
	}

	static SmoothScroll = class {
		static init() {
			var anchorLinks = [];

			$('.anchorlink').each((i, item) => {
				anchorLinks.push(new Common.SmoothScroll.AnchorLink(item));
			})
			return anchorLinks;
		}
		static scroll(target) {
			let top = 0;

			if(typeof target == 'number') {
				top = target;
			}
			if(typeof target == 'object') {
				top = $(target).offset().top;
			}
			$('html,body').stop().animate({scrollTop : top}, 600, 'easeOutExpo');
		}
		static AnchorLink = class {
			constructor(view) {
				this.view = $(view);
				this.view.click(this.clickEventHandler.bind(this));
				this.view.target = $(this.view.attr('href'));
			}
			clickEventHandler(e) {
				e.preventDefault();
				Common.SmoothScroll.scroll(this.view.target.get(0) ? this.view.target.get(0) : 0)
			}
		}
	}

	static Form = class {

		constructor() {
			$('.cmn-form-select').each(function() {
				new Common.Form.Select($(this));
			})
		}
		static Select = class {
			constructor(view) {
				this.view = view;
				this.view.change(this.change.bind(this))
				this.change();
			}
			change() {
				if(this.view.val()) {
					this.view.addClass('selected');
				} else {
					this.view.removeClass('selected');
				}
			}
		}
	}

	
	// static Accordion = class {
	// 	static init() {
	// 		$('[data-ac]').each((i, item) => {
	// 			new Common.Accordion($(item));
	// 		})
	// 	}
	// 	constructor(view) {
	// 		this.view = view;
	// 		this.view.target = this.view.next();
	// 		this.view.click(this.toggle.bind(this));
	// 	}
	// 	toggle(e) {
	// 		e.preventDefault()
	// 		if(!this.view.hasClass('open')) {
	// 			this.open();
	// 		} else {
	// 			this.close();
	// 		}
	// 	}
	// 	open() {
	// 		this.view.addClass('open');

	// 		var h = this.view.target.height();
	// 		var H = this.view.target.height('auto').height();

	// 		this.view.target.height(h);
	// 		this.view.target.stop().animate({height : H}, function() {
	// 			$(this).height('auto');
	// 		})
	// 	}
	// 	close() {
	// 		this.view.removeClass('open');

	// 		this.view.target.stop().animate({height : 0}, function() {
	// 		})
	// 	}
	// }

	static AudioPlayer = class {
		static instances = [];
		static init() {
			$('[data-audio]').each((i, item) => {
				Common.AudioPlayer.instances.push(new Common.AudioPlayer($(item)));
			});
		}
		constructor(view) {
			this.view = view;
			this.path = this.view.data('audio');
			this.player = null;
			this.view.click(() => {
				if(!this.view.hasClass('playing')) {
					this.play();
				} else {
					this.pause();
				}
			})
		}
		play() {
			Common.AudioPlayer.instances.forEach(audioplayer => {
				audioplayer.pause();
			});
			if(!this.player) {
				this.player = new Audio(this.path);
				this.player.addEventListener('ended', () => {
					this.view.removeClass('playing');
				});
			}
			this.player.currentTime = 0;
			this.player.play();
			this.view.addClass('playing');
		}
		pause() {
			if(this.player) {
				this.player.pause();
			}
			this.view.removeClass('playing');
		}
	}

	static WP_REST_API_Client = class {

		static instances = [];
		static init() {
			$('[data-wpapi]').each((i, item) => {
				Common.WP_REST_API_Client.instances.push(new Common.WP_REST_API_Client($(item)));
			});
		}
		constructor(view) {
			this.view = view;
			this.type = this.view.data('wpapi');
			this.limit = this.view.data('limit');
			// console.log(this.view.find('.template').html());
			this.template = Handlebars.compile(this.view.find('.template').html());

			this.get();
		}
		get() {
			$.ajax('/wordpress-api', {
				data : {
					type : this.type,
					limit : this.limit
				},
				dataType : 'json'
			}).done((json) => {
				// console.log(json["result"]);
				// console.log(this.template(json));
				this.view.html(this.template(json));
			});
		}
	}
}

$(function() {
	common = new Common;
	pages = {};
});

document.querySelectorAll('.js-ac').forEach((trigger) => {
	const target = trigger.nextElementSibling;
	trigger.addEventListener('click', (e) => {
		
		if(trigger.classList.contains('is-open')){
			target.classList.remove('is-open');
			trigger.classList.remove('is-open');
		} else {
			target.classList.add('is-open');
			trigger.classList.add('is-open');
		}

		// console.log('aiueo');

		const mediaQuery = window.matchMedia('(max-width: 1200px)');
		// ページが読み込まれた時に実行
		handle(mediaQuery);
		// ウィンドウサイズを変更しても実行（ブレイクポイントの監視）
		mediaQuery.addListener(handle);
		function handle(mm) {
			if (mm.matches) {
				// ウィンドウサイズ768px以下のときの処理
				e.preventDefault();
			}
		}
	})
});